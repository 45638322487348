import React, {useState, useEffect} from "react";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';
import ListLoader from "../Loaders/ListLoader";

function ShipmentSetting()
{
    let navigate = useNavigate();
    const[weightType,setweightType]=useState('');
    const[awbSerise,setawbSerise]=useState('');
    const[defaultAgentCom,setdefaultAgentCom]=useState('');
    const[DefaultDelivery,setDefaultDelivery]=useState('');
    const[MinCod,setMinCod]=useState('');
    const[CodPrecent,setCodPrecent]=useState('0');
    const[MaxCod,setMaxCod]=useState('');
    const[RTO,setRTO]=useState('');
    const[pickup,setPickup]=useState('');
    const[manifest,setManifest]=useState('');
    const[delivery,setDelivery]=useState('');
    const[BagPrefix,setBagPrefix]=useState('');
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);

    useEffect(()=>{
        FetchShipmentData();
    },[]);
    function FetchShipmentData(){
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchShipmentData',
            headers:headers1,
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setBagPrefix(data.default_awb_pri_fix);
                setweightType(data.weightType);
                setawbSerise(data.default_awb_no);
                setdefaultAgentCom(data.default_agent_commission);
                setDefaultDelivery(data.delivery_days);
                setMinCod(data.cod_fix_price);
                setCodPrecent(data.cod_fix_rate);
                setMaxCod(data.cod_max_value);
                setRTO(data.return_ship_per);
                setPickup(data.pickup_commission);
                setManifest(data.pickup_commission);
                setDelivery(data.delivery_commission);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    function handleSubmit(){
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'UpdateShipmentData',
            headers:headers1,
            data: {
                "BagPrefix" : BagPrefix,
                "weightType" : weightType,
                "awbSerise" : awbSerise,
                "defaultAgentCom" : defaultAgentCom,
                "DefaultDelivery" : DefaultDelivery,
                "MinCod" : MinCod,
                "CodPrecent" : CodPrecent,
                "MaxCod" : MaxCod,
                "RTO" : RTO,
                "pickup" : pickup,
                "manifest" : manifest,
                "delivery" : delivery,
            }
          })
          .then(function (response) {
            if(response.data.status == 200){
                toast.success(response.data.message);
            }else{
                toast.error(response.data.message);
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                // logout();
                // navigate('/');
                // window.location.reload();
            }
            setSubloading(false);
            // console.log(error);
          });
    }
    
    return(
            <div className="col-12 col-md">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li className="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                    <i className="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                <i className="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div className="ps-2 ">
                                <h2 className="h4">Settings</h2>
                            </div>
                        </div>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                    </div>
                </div>

                <div className="card" style={{minHeight:'150px'}}>
                {Loading==true ? <ListLoader/> :
                    <div className="card-body">
                    <h6>Shipment Settings</h6>
                        <div className="row">
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">System Weight In</label>
                                <select className="form-select" onChange={(e)=>setweightType(e.target.value)}>
                                    <option value="KG">KG</option>
                                    <option value="LBS">LBS</option>
                                </select>
                            </div>
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">Set Series start AWB no.<a href="#" title="AWB number start from" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-content="System will start AWB no series from this number when there is no shipment on the system."><i className="bx bx-help-circle"></i></a></label>
                                <input type="text" value={awbSerise} onChange={(e)=>setawbSerise(e.target.value)} className="form-control" id="" placeholder="Please enter Series start AWB no." />
                            </div>
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">Set Default Agent Commission%</label>
                                <input type="number" value={defaultAgentCom} onChange={(e)=>setdefaultAgentCom(e.target.value)} className="form-control" id="" placeholder="Please enter Commission % " />
                            </div>
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">Set default expected days for delivery</label>
                                <input type="number" value={DefaultDelivery} onChange={(e)=>setDefaultDelivery(e.target.value)} className="form-control" id="" placeholder="Please days" />
                            </div>
                            <div className="col-6  mb-3">
                                <label for="" className="form-label">Set Bag Prefix</label>
                                <input type="text" value={BagPrefix} onChange={(e)=>setBagPrefix(e.target.value)} className="form-control" id="" placeholder="Please days" />
                            </div>
                            <div className="col-12  mb-3">
                                <div className="card">
                                    <div className="card-body">
                                    <strong>COD Settings</strong>
                                    <div className="row mt-3">
                                        <div className="col-6  mb-3">
                                            <label for="" className="form-label">Minimum COD Charges</label>
                                            <input type="number" value={MinCod} onChange={(e)=>setMinCod(e.target.value)} className="form-control" id="" placeholder="Please enter COD Fix price details" />
                                        </div>
                                        {/* <div className="col-6  mb-3">
                                            <label for="" className="form-label">COD Charges in %</label>
                                            <input type="number" value={CodPrecent} onChange={(e)=>setCodPrecent(e.target.value)} className="form-control" id="" placeholder="Please enter COD charge % only in number. eg 10" />
                                        </div> */}
                                        <div className="col-6  mb-3">
                                            <label for="" className="form-label">Maximum COD Value</label>
                                            <input type="text" value={MaxCod} onChange={(e)=>setMaxCod(e.target.value)} className="form-control" id="" placeholder="Please enter COD Max Value system allow on shipment booking" />
                                        </div>
                                        <div className="col-12  mb-3">
                                            <small>Note: System will calculate COD charge based on minimum and COD value % and charge the higher value.</small>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-12  mb-3">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="mb-3">
                                        <strong>Pickup Shipment Pricing in %</strong>
                                    </div>
                                    <label for="" className="form-label">Pickup Shipment Price (Pickup in %)</label>
                                    <input type="text" value={pickup} onChange={(e)=>setPickup(e.target.value)} className="form-control" id="" placeholder="Please enter Pickup Shipment Price (Pickup in %) details" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12  mb-3">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="mb-3">
                                        <strong>Manifest Shipment Pricing in %</strong>
                                    </div>
                                    <label for="" className="form-label">Manifest Shipment Price (Manifest in %)</label>
                                    <input type="text" value={manifest} onChange={(e)=>setManifest(e.target.value)} className="form-control" id="" placeholder="Please enter Manifest Shipment Price (Manifest in %) details" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12  mb-3">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="mb-3">
                                        <strong>Delivery Shipment Pricing in %</strong>
                                    </div>
                                    <label for="" className="form-label">Delivery Shipment Price (Delivery in %)</label>
                                    <input type="text" value={delivery} onChange={(e)=>setDelivery(e.target.value)} className="form-control" id="" placeholder="Please enter Delivery Shipment Price (Delivery in %) details" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12  mb-3">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="mb-3">
                                        <strong>RTO Shipment Pricing in %</strong>
                                    </div>
                                    <label for="" className="form-label">Return Shipment Price (RTO in %)</label>
                                    <input type="text" value={RTO} onChange={(e)=>setRTO(e.target.value)} className="form-control" id="" placeholder="Please enter Return Shipment Price (RTO in %) details" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Subloading==false?
                        <div className="d-grid col-md-2 mt-2">
                            <button onClick={handleSubmit} className="btn btn-primary">Update Settings</button>
                        </div>
                        :
                        <div className="d-grid col-md-2 mt-2">
                            <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                        </div>
                        }
                    </div>
                }
                </div>

            </div>
    )
}
export default ShipmentSetting;