import React, {useState, useEffect}from "react";
import { useNavigate,Link } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import Global from "../../APIConfig";
import OnlineStatus from "../../Onlinestatus";

function Header() {
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [showSelectBox, setShowSelectBox] = useState(false);
    const [filteredData, setFilteredData] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedoption, setSelectedoption] = useState('');
    const [trackingnumber, setTrackingnumber] = useState('');
    const useritems = JSON.parse(localStorage.getItem('user'));
    const user_privilage_string = useritems.user_privilage_array;
    const user_privilage_array = user_privilage_string.split(',').map(Number);
    
    useEffect(()=>{
        const items = JSON.parse(localStorage.getItem('selectedoption'));
        if (items) {
            setSelectedoption(items);
        }
        const dropdownMenu = document.querySelector('.new_request_menu .dropdown');
        const overlay = document.querySelector('.overlay');

        const showDropdown = () => {
        overlay.classList.add('show');
        };

        const hideDropdown = () => {
        overlay.classList.remove('show');
        };

        if (dropdownMenu) {
        dropdownMenu.addEventListener('show.bs.dropdown', showDropdown);
        dropdownMenu.addEventListener('hide.bs.dropdown', hideDropdown);
        }

        // Cleanup event listeners on component unmount
        return () => {
        if (dropdownMenu) {
            dropdownMenu.removeEventListener('show.bs.dropdown', showDropdown);
            dropdownMenu.removeEventListener('hide.bs.dropdown', hideDropdown);
        }
    };
    },[])
    const items = JSON.parse(localStorage.getItem('user'));

    const userlogout=()=>{
        logout();
        navigate('/');
    }

    const linkdata = [
    {name:'Master Dashboard',link:'/Admin-Dash'},
    {name:'Shipment List',link:'/Shipmentlist/All'},
    {name:'Undelivered Shipment List',link:'/Shipmentlist/B'},
    {name:'Delivered Shipment List',link:'/Shipmentlist/D'},
    {name:'Missing Shipment List',link:'/Shipmentlist/B'},
    {name:'NDR Shipment List',link:'/Shipmentlist/B'},
    {name:'RTO Shipment List',link:'/Shipmentlist/B'},
    {name:'Add Shipment',link:'/Createshipment'},
    {name:'Pickup Run Sheet (PRS) Running',link:'/PickupList/Running'},
    {name:'Pickup Run Sheet (PRS) Completed',link:'/PickupList/Completed'},
    {name:'Generate Pickup Run Sheet (PRS)',link:'/Generatepickup'},
    {name:'Generate Bag',link:'/AddBag'},
    {name:'Bags Running',link:'/BagList/Running'},
    {name:'Bags Completed',link:'/BagList/Completed'},
    {name:'Generate Bag Manifest',link:'/AddBagManifest'},
    {name:'Bags Manifests Running',link:'/BagManifestList/Running'},
    {name:'Bags Manifests Completed',link:'/BagManifestList/Completed'},
    {name:'Generate Shipment Manifest',link:'/AddShipmentManifest'},
    {name:'Shipment Manifest Running',link:'/ShipmentManifestList/Running'},
    {name:'Shipment Manifest Completed',link:'/ShipmentManifestList/Completed'},
    {name:'Generate Delivery Run Sheet (DRS)',link:'/AddDrs'},
    {name:'Delivery Run Sheet (DRS) Running',link:'/DrsList/Running'},
    {name:'Delivery Run Sheet (DRS) Completed',link:'/DrsList/Completed'},
    {name:'Warehouse Inbound Scanner',link:'/WarehouseScanner/Pickup'},
    {name:'Customers All List',link:'/CustomerList/All'},
    {name:'Credit Customers List',link:'/CustomerList/C'},
    {name:'NonCredit Customers List',link:'/CustomerList/NC'},
    {name:'Contract Customers List',link:'/CustomerList/U'},
    {name:'Add Customer',link:'/CustomerAdd'},
    {name:'All Hub',link:'/HubList'},
    {name:'Add Hub',link:'/HubAdd'},
    {name:'All Agents',link:'/AgentList/All'},
    {name:'Add Agent',link:'/AgentAdd'},
    {name:'Add Staff',link:'/StaffAdd'},
    {name:'All Staff',link:'/StaffList'},
    {name:'Set Staff Privillages',link:'/Privillages'},
    {name:'Add Driver',link:'/DriverAdd'},
    {name:'All Drivers',link:'/DriverList'},
    {name:'General Settings',link:'/GeneralSetting'},
    {name:'System Settings',link:'/SystemSetting'},
    {name:'Shipment Settings',link:'/ShipmentSetting'},
    {name:'Account Settings',link:'/AccountSetting'},
    {name:'Carrier Settings',link:'/CarrierSetting'},
    {name:'Payment Getway Settings',link:'/PaymentGetwaySetting'},
    {name:'SMTP Settings',link:'/SmtpSetting'},
    {name:'Social Settings',link:'/SocialSetting'},
    {name:'Email Template Settings',link:'/EmailTemplate'},
    {name:'Sms Template Settings',link:'/SmsTemplate'},
    {name:'Database Backup Settings',link:'/DatabaseBackup'},
    ];

    const handlesearchdata=(val)=>{
        setSearchTerm(val);
        if(val.length >2)
        {
            const filtered = linkdata.filter(item => 
                item.name.toLowerCase().includes(val)
            );
            if(filtered.length >0)
            {
                setFilteredData(filtered);
                setShowSelectBox(true);
            }
        }
        else
        {
            setShowSelectBox(false);
        }
    }
    const Trackingshipment=()=>{
        if(trackingnumber !="")
        {
            navigate(`/ShipmentTracking/${trackingnumber}`);
        }
        else
        {
            toast.warn("Please Enter AWB No.");
        }
      }

      const handelSelectedoption=(val)=>{
        localStorage.removeItem('selectedoption');
        localStorage.setItem('selectedoption', JSON.stringify(val));
        setSelectedoption(val);
      }

    return(
        <header>
            <OnlineStatus/>
    <nav className="navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-0">
        <div className="container-fluid px-0">
            <div className="d-flex justify-content-between w-100" id="navbarSupportedContent">
            <div className="d-flex align-items-center">
                <form className="navbar-search form-inline" id="navbar-search-main">
                <div className="input-group input-group-merge search-bar">
                    <span className="input-group-text" id="topbar-addon">
                        <svg className="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                        </svg>
                    </span>
                    <input type="text" className="form-control" id="topbarInputIconLeft" placeholder="Search" aria-label="Search" aria-describedby="topbar-addon" value={searchTerm} onChange={(e)=>handlesearchdata(e.target.value)} />
                </div>
                {showSelectBox &&
                <ul className="mastersearch">
                    {filteredData.map((item, index) => (
                        <li key={index} className="searchtypehead">
                            <a href={item.link}>{item.name}</a>
                        </li>
                    ))}
                </ul>
                }
                </form>
            </div>
            <div className="ms-auto">
                <div className="btn-group">
                    <a href="/Createshipment" className="btn btn-gray-800 d-inline-flex align-items-center me-2 dropdown-toggle">
                        <svg className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                    
                    </a>
                    {/* <div className="dropdown-menu">
                        <div className="dropdown-item">
                                <div className="card-body">
                                    <h4>Add Shipment</h4>
                                    <small>Add a Single shipment or choose to add multiple shipment in bulk through CSV or excel sheet upload</small>
                                    <div className="row mt-3">
                                        <div className="col-12 col-md-4">
                                            <div className="d-flex ">
                                                <div className="p-1">
                                                    <i className="bx bx-cube"></i>
                                                </div>
                                                <div className="p-1 ">
                                                    <a href="/Createshipment">Add Shipment</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="d-flex ">
                                                <div className="p-1">
                                                    <i className="bx bx-copy"></i>
                                                </div>
                                                <div className="p-1 ">
                                                    <a href="/Createshipment">Multiple Shipment</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <h4>Add Pickup</h4>
                                    <div className="row justify-content-between">
                                        <div className="col-12 col-md-4">
                                            <div className="d-flex ">
                                                <div className="p-1">
                                                    <i className="bx bx-collection"></i>
                                                </div>
                                                <div className="p-1 ">
                                                    <a href="/Generatepickup">Generate PRS</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md">
                                            <div className="d-flex ">
                                                <div className="p-1">
                                                    <i className="bx bx-time"></i>
                                                </div>
                                                <div className="p-1 ">
                                                    <a href="">Schedule Customer PRS</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <h4>Add Manifest</h4>
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="d-flex ">
                                                <div className="p-1">
                                                    <i className="bx bx-cube-alt"></i>
                                                </div>
                                                <div className="p-1 ">
                                                    <a href="/AddBag">Generate New Bag</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md">
                                            <div className="d-flex ">
                                                <div className="p-1">
                                                    <i className="bx bx-cube-alt"></i>
                                                </div>
                                                <div className="p-1 ">
                                                    <a href="/AddBagManifest">Generate Bag Manifest</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />
                                    <h4>Add Delivery Sheet</h4>
                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="d-flex ">
                                                <div className="p-1">
                                                    <i className="bx bx-run"></i>
                                                </div>
                                                <div className="p-1 ">
                                                    <a href="/AddDrs">Generate DRS</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <h4>Track Shipment</h4>
                                    <div className="row ">
                                        <div className="col-12 col-md">
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1">
                                                <svg className="icon icon-xxs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                                </span>
                                                <input type="text" className="form-control" id="exampleInputIconLeft" placeholder="Search" aria-label="Search" onChange={(e)=>setTrackingnumber(e.target.value)} />
                                                <button className="btn btn-primary text-light" onClick={()=>Trackingshipment()}>Track</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="ms-auto">
                <div className="btn-group">
                <button type="button" className="btn btn-gray-700">{selectedoption}</button>
                <button type="button" className="btn btn-gray-700 dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to={`/OperationalDashBoard`} onClick={()=>handelSelectedoption("Operational Dashbaord")}>Operational Dashbaord</Link></li>
                    {items.usertype =='AD' || [9,10,11,12,13].some(value => user_privilage_array.includes(value)) ?
                    <li><Link className="dropdown-item" to={`/CustomerDashBoard`} onClick={()=>handelSelectedoption("Customers & Users Dashbaord")}>Customers & Users Dashbaord</Link></li>
                    :""}
                    {items.usertype =='AD' || user_privilage_array.includes(20) ?
                    <li><Link className="dropdown-item" to={`/FinanceDashBoard`} onClick={()=>handelSelectedoption("Finance Dashbaord")}>Finance Dashbaord</Link></li>
                    :""}
                    {items.usertype =='AD' || user_privilage_array.includes(1) ?
                    <li><Link className="dropdown-item" to={`/CmsDashBoard`} onClick={()=>handelSelectedoption("CMS Dashbaord")}>CMS Dashbaord</Link></li>
                    :""}
                    {items.usertype =='AD' || items.usertype =='M' || [14, 15, 16, 17, 18, 19].some(value => user_privilage_array.includes(value)) ?
                    <li><Link className="dropdown-item" to={`/SystemSettingDashBoard`} onClick={()=>handelSelectedoption("System Settings")}>System Settings</Link></li>
                    :""}
                    <li><hr className="dropdown-divider" /></li>
                    <li><Link className="dropdown-item" to={`/Admin-Dash`} onClick={()=>handelSelectedoption("Master Dashbaord")}>Master Dashbaord</Link></li>
                </ul>
                </div>
            </div>
            <ul className="navbar-nav align-items-center custom_had_nav">
                {/* <li className="nav-item dropdown">
                    <a className="nav-link text-dark notification-bell unread dropdown-toggle" data-unread-notifications="true" href="#" role="button" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                        <i class="bx bx-bell"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                        <div className="list-group list-group-flush">
                        <a href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">Notifications</a>
                        <a href="#" className="list-group-item list-group-item-action border-bottom">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                <img alt="Image placeholder" src="assets/img/team/profile-picture-1.jpg" className="avatar-md rounded" />
                                </div>
                                <div className="col ps-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h4 className="h6 mb-0 text-small">Jose Leos</h4>
                                    </div>
                                    <div className="text-end">
                                        <small className="text-danger">a few moments ago</small>
                                    </div>
                                </div>
                                <p className="font-small mt-1 mb-0">Added you to an event "Project stand-up" tomorrow at 12:30 AM.</p>
                                </div>
                            </div>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action border-bottom">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                <img alt="Image placeholder" src="assets/img/team/profile-picture-2.jpg" className="avatar-md rounded" />
                                </div>
                                <div className="col ps-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h4 className="h6 mb-0 text-small">Neil Sims</h4>
                                    </div>
                                    <div className="text-end">
                                        <small className="text-danger">2 hrs ago</small>
                                    </div>
                                </div>
                                <p className="font-small mt-1 mb-0">You've been assigned a task for "Awesome new project".</p>
                                </div>
                            </div>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action border-bottom">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                <img alt="Image placeholder" src={`${Global.logoUrl}${items.profile_image}`} className="avatar-md rounded" />
                                </div>
                                <div className="col ps-0 m-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h4 className="h6 mb-0 text-small">Roberta Casas</h4>
                                    </div>
                                    <div className="text-end">
                                        <small>5 hrs ago</small>
                                    </div>
                                </div>
                                <p className="font-small mt-1 mb-0">Tagged you in a document called "Financial plans",</p>
                                </div>
                            </div>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action border-bottom">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                <img alt="Image placeholder" src="assets/img/team/profile-picture-4.jpg" className="avatar-md rounded" />
                                </div>
                                <div className="col ps-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h4 className="h6 mb-0 text-small">Joseph Garth</h4>
                                    </div>
                                    <div className="text-end">
                                        <small>1 d ago</small>
                                    </div>
                                </div>
                                <p className="font-small mt-1 mb-0">New message: "Hey, what's up? All set for the presentation?"</p>
                                </div>
                            </div>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action border-bottom">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                <img alt="Image placeholder" src="assets/img/team/profile-picture-5.jpg" className="avatar-md rounded" />
                                </div>
                                <div className="col ps-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h4 className="h6 mb-0 text-small">Bonnie Green</h4>
                                    </div>
                                    <div className="text-end">
                                        <small>2 hrs ago</small>
                                    </div>
                                </div>
                                <p className="font-small mt-1 mb-0">New message: "We need to improve the UI/UX for the landing page."</p>
                                </div>
                            </div>
                        </a>
                        <a href="#" className="dropdown-item text-center fw-bold rounded-bottom py-3">
                            <svg className="icon icon-xxs text-gray-400 me-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd"></path></svg>
                            View all
                        </a>
                        </div>
                    </div>
                </li> */}
                <li className="nav-item dropdown">
                    <a className="nav-link text-dark "  href="#"  data-bs-toggle="offcanvas" data-bs-target="#mega_menu">
                        <i className="bx bx-dialpad-alt"></i>
                    </a>
                </li>
                <li className="nav-item dropdown ms-lg-3">
                <a className="nav-link dropdown-toggle pt-1 px-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="media d-flex align-items-center">
                    <img className="avatar rounded-circle" alt="Image placeholder" src={`${Global.logoUrl}${items.profile_image}`} />
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                        <span className="mb-0 font-small fw-bold text-gray-900">{items.name}</span>
                    </div>
                    </div>
                </a>
                <div className="dropdown-menu dashboard-dropdown dropdown-menu-end mt-2 py-1">
                    <a className="dropdown-item d-flex align-items-center" href={`/AdminProfile/${items.unique_id}`}>
                    <svg className="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>
                    My Profile
                    </a>
                    {/* <a className="dropdown-item d-flex align-items-center" href="/Settings">
                    <svg className="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path></svg>
                    Settings
                    </a> */}
                    {/* <a className="dropdown-item d-flex align-items-center" href="#">
                    <svg className="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z" clip-rule="evenodd"></path></svg>
                    Messages
                    </a>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                    <svg className="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clip-rule="evenodd"></path></svg>
                    Support
                    </a> */}
                    <div role="separator" className="dropdown-divider my-1"></div>
                    <button className="dropdown-item d-flex align-items-center" onClick={()=>userlogout()}>
                    <svg className="dropdown-icon text-danger me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>                
                    Logout
                    </button>
                </div>
                </li>
            </ul>
            </div>
        </div>
    </nav>
</header>
    )
}

export default Header;