import React, {useState} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Global from "../../APIConfig";
import { useNavigate, useParams } from "react-router-dom";
import AuthUser from "../../AuthUser";
import successtone from "../success.mp3";
import errortone from "../error.mp3";


function WarehouseScannerPickup(){
const navigate = useNavigate();
const {type} = useParams();
const [slipno, setSlipno] = useState('');
const [scantype, setScantype] = useState(type);
const{headers1,headers2,logout}=AuthUser();

const userdata = JSON.parse(localStorage.getItem('user'));
    
    const Globalreceiverslipno=()=>{
        if(scantype =='Manifested_Bag')
        {
            axios({
                method: 'post',
                url: Global.baseUrl+'GlobalreceiverBagManifest',
                headers:headers1,
                data: {
                    userid:userdata.id,
                    usertype:userdata.usertype,
                    hub_name:userdata.hub_name,
                    branch_location:userdata.branch_location,
                    bag_id:slipno

                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    const audio = new Audio(successtone);
                    audio.play()
                    toast.success(response.data.message);
                }
                else
                {
                    const audio = new Audio(errortone);
                    audio.play()
                    toast.error(response.data.message);
                }
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
        }
        else if(scantype =='Manifested_Shipment')
        {
            axios({
                method: 'post',
                url: Global.baseUrl+'GlobalreceiverShipmentManifest',
                headers:headers1,
                data: {
                    userid:userdata.id,
                    usertype:userdata.usertype,
                    hub_name:userdata.hub_name,
                    branch_location:userdata.branch_location,
                    slip_no:slipno

                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    const audio = new Audio(successtone);
                    audio.play()
                    toast.success(response.data.message);
                }
                else
                {
                    const audio = new Audio(errortone);
                    audio.play()
                    toast.error(response.data.message);
                }
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
        }
        else if(scantype =='Delivery')
        {
            alert("working pending");
        }
        else
        {
            axios({
                method: 'post',
                url: Global.baseUrl+'GlobalreceiverPickup',
                headers:headers1,
                data: {
                    userid:userdata.id,
                    usertype:userdata.usertype,
                    hub_name:userdata.hub_name,
                    branch_location:userdata.branch_location,
                    slip_no:slipno

                }
            })
            .then(function (response) {
                if(response.data.status == 200)
                {
                    const audio = new Audio(successtone);
                    audio.play()
                    toast.success(response.data.message);
                }
                else
                {
                    const audio = new Audio(errortone);
                    audio.play()
                    toast.error(response.data.message);
                }
            })
            .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
        }
    }

    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer />
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-6 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i class="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Warehouse Inbound Scanner</li>
                        </ol>
                    </nav>
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <button class="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i class="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div class="ps-2 ">
                            <h2 class="h4">Warehouse Inbound Scanner</h2>
                        </div>
                    </div>
                </div>
                
                <div class="btn-toolbar mb-2 mb-md-0">
                    
                </div>
            </div>


            <div class="row">
                <div class="col-12 col-md">
                    <div class="custom_nav_data_table warehouse_scanner">
                        <ul class="nav nav-tabs ">
                            <li class="nav-item ">
                              <a class={scantype == 'Pickup' ? 'nav-link active':'nav-link'} href="/WarehouseScanner/Pickup">
                                <h1><i class="bx bx-collection"></i></h1>
                                Scan Pickup Shipments
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class={scantype =='Manifested_Bag' ? 'nav-link active':'nav-link'} href="/WarehouseScanner/Manifested_Bag">
                                <h1><i class="bx bx-cuboid"></i></h1>
                                Scan Manifested Bags</a>
                            </li>
                            <li class="nav-item">
                              <a class={scantype =='Manifested_Shipment' ? 'nav-link active':'nav-link'} href="/WarehouseScanner/Manifested_Shipment">
                                <h1><i class="bx bx bx-cube-alt"></i></h1>
                                Scan Manifeseted Shipments</a>
                            </li>
                            {/* <li class="nav-item">
                              <a class={scantype =='Delivery' ? 'nav-link active':'nav-link'} href="/WarehouseScanner/Delivery">
                                <h1><i class="bx bx-run"></i></h1>
                                Scan Delivery Shipments</a>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12 col-md-3">
                    <h3 class="text-primary"><i class="bx bx-sun"></i></h3>
                    <ul class="scan_left">
                        <li>Arrival of shipments picked up by drivers or messengers into the warehouse</li>
                        <li>Barcode scanner to swiftly scan the barcode associated with each shipment.</li>
                        <li>Manually input the AWB (Air Waybill) number if scanning is not feasible.</li>
                        <li>Once the shipment details are entered, the system will integrate them into the warehouse inventory, facilitating seamless tracking and management.</li>
                    </ul>
                </div>
                <div class="col-12 col-md">
                    {scantype ==='Pickup' &&
                        <h3>Scan Pickup Shipments into Warehouse</h3>
                    }
                    {scantype ==='Manifested_Bag' &&
                        <h3>Scan Manifested Bags</h3>
                    }
                    {scantype ==='Manifested_Shipment' &&
                        <h3>Scan Manifeseted Shipments</h3>
                    }
                    {/* {scantype ==='Delivery' &&
                        <h3>Scan Delivery Shipments</h3>
                    } */}
                    <div class="card px-5 py-3 mt-4">
                      <div class="card-body">
                        <div class="mb-2">
                        {scantype ==='Pickup' &&
                            <label for="exampleInputIconLeft">Scan Pickup Shipments</label>
                        }
                        {scantype ==='Manifested_Bag' &&
                            <label for="exampleInputIconLeft">Scan Manifested Bag</label>
                        }
                        {scantype ==='Manifested_Shipment' &&
                            <label for="exampleInputIconLeft">Scan Manifested Shipments</label>
                        }
                        {/* {scantype ==='Delivery' &&
                            <label for="exampleInputIconLeft">Scan Delivery Shipments</label>
                        } */}
                            <div class="input-group input-group-lg">
                                <span class="input-group-text" id="basic-addon1">
                                <i class="bx bx-barcode"></i>
                                </span>
                                <input type="text" class="form-control form-control-lg" id="" value={slipno} onChange={(e)=>setSlipno(e.target.value)} placeholder={scantype=='Manifested_Bag' ? 'Enter Bag Number':'Enter AWB Number'} aria-label="Search" />
                            </div>
                        </div>
                        <div class="d-grid">
                          <button class="btn btn-primary btn-block" onClick={()=>Globalreceiverslipno()}>Submit</button>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
        </>

    );
}
export default WarehouseScannerPickup;